
/************************
 *** Various Fudging
 ************************/

html, body, #app, .page {
  width: 100%;
  height: 100%;
}

.main-container {
  position: relative;
  min-height: 100vh;
}

section.section:last-child {
  padding: 24px;
}

footer.footer {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  bottom: 0;
  background-color: white;
}

.compact-level-nav {
  max-width: 40rem;
  margin: 1rem auto;
  margin-top: 0px;
}

table {
    table-layout: fixed;
}

.title, .subtitle, .content {
  color: #555;
}

/********************************
 * Login Box
 *******************************/

.login-container {
  width: 100%;
  display: grid;
  place-items: center;
}

.box.login-box {
  width: 100%;
  max-width: 400px;
}

.is-hidden-touch.login-padding {
  height: 100px;
}

/********************************
 * Remote / Client Box
 *******************************/

.box.stun-remote:last-child {
  margin-bottom: 1.5rem;
}

.box.stun-client:last-child {
  margin-bottom: 1.5rem;
}
